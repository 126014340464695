<template>
    <div class="aboutUs">
        <articleIndex bigImg="https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/about_us.png" title="关于我们" :leftList="leftList" @changeIndex="changeIndex">
            <slot slot="right">
                <div class="aboutusBox">
                    <img v-show="showIndex == 0" src="https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/qiyejianjie.png" />
                    <img v-show="showIndex == 1" src="https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/qiyeshiming.png" />
                    <img v-show="showIndex == 2" src="https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/ruanjianfuwu.png" />
                    <img v-show="showIndex == 3" src="https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/qiyewenhua.png" />
                    <img v-show="showIndex == 4" src="https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/fazhanlicheng.png" />
                </div>
            </slot>
        </articleIndex>
    </div>
</template>

<script type="text/ecmascript-6">
export default {
    components: {},
    data() {
        return {
            leftList: [{ text: '企业简介' }, { text: '企业使命' }, { text: '软件服务' }, { text: '企业文化' }, { text: '发展历程' }],
            showIndex: 0
        }
    },
    created() {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        this.showIndex = this.$route.query.active
    },
    mounted() {},
    methods: {
        changeIndex(text, index) {
            this.$router.push('/index/aboutUs?active=' + index)
            this.showIndex = index
        }
    },
    watch: {
        $route: function (to, from) {
            this.showIndex = this.$route.query.active
        }
    }
}
</script>

<style lang="less" scoped>
.aboutusBox {
    text-align: center;
}
</style>
